import React, { Component } from "react";
import style from './loading.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Icon from "components/icon";
import Layout from "components/layout/basic";
export default class Loading extends Component {
	render() {
		return (
			<Layout>
				<div id={style.loader}>
					<div style={style.loaderWrapper}>
						<Icon width="200" height="200" icon="loading" />
						<h1>Loading…</h1>
					</div>
				</div>
			</Layout>
		)
	}
}