import React, { Component } from "react";

import Layout from "components/layout/basic"
import SEO from 'shared/seo'
import ZComponent from "../lib/ZComponent";
import Loading from "components/loading/";
import Link from 'shared/link';
import style from 'components/layout/internal.module.scss';

export default class NotFoundPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			"404": false,
			data: [],
			loading: true
		}
	}

	checkData() {
		if (typeof window !== "undefined" && window) {
			const data = window.fetch(`${process.env.GATSBY_ZMS_API_URL}get${window.location.pathname}`)
				.then(function (response) {
					return response.json()
						.then((result) => {
							return result;
						});
				})
				.catch(function (err) {
					return;
				});

			data.then((result) => {
				if (result.data.length !== 0) {
					this.setState({
						"404": false,
						data: result.data,
						loading: false
					});
				} else {
					this.setState({
						"404": true,
						loading: false
					});
				}
			})
		}
	}

	componentDidMount() {
		this.checkData();
	}

	render() {
		if (this.state.data.length !== 0) {
			return <ZComponent data={this.state.data} />
		} else if (this.state["404"] && !this.loading) {
			return (
				<Layout>
					<SEO title="404: Not found | Heritage Medical Associates" />
					<div className="grid-container">
						<div className="grid-x margin-grid">
							<div className="cell small-12 medium-offset-2 medium-8 end">
								<h1 className={style.pageTitle}>PAGE NOT FOUND</h1>
								<section className={[style.mainContent, "main-content"].join(' ')}>
									<h4>Sorry, this page no longer exists. </h4>
									<p>The link you followed may be broken, or the page may have been removed. Please try <Link to="/search/">searching for something else</Link> or <Link to="/">visit or our homepage</Link>.</p>
								</section>
							</div>
						</div>
					</div>
				</Layout>
			);
		} else {
			return <Loading />;
		}
	}
}

